import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { useSoftUIController, setMiniSidenav } from "context";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import Footer from "app/components/footer";
import Sidenav from "app/components/sidenav";
import DashboardNavbar from "app/components/dashboard-navbar";
import DashboardLayout from "pages/layout/dashboard-layout";

import theme from "assets/theme";
import routes from "routes/admin.routes";
import brand from "assets/images/logos/logo-transparent-green.png";
import miniBrand from "assets/images/logos/mini-logo-transparent-green.png";
import PrivateRoute from "auth/private-route";
import { get } from "api";
import { SERVER_URL_PREFIX } from "api";

const App = () => {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [unprocessedOrders, setUnprocessedOrders] = useState(0);
  const [unprocessedReviews, setUnprocessedReviews] = useState(0);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // Function to fetch unprocessed data
  const fetchUnprocessedData = () => {
    get(`${SERVER_URL_PREFIX}/api/admin/notification/stats/unprocessed`)
      .then((response) => response.json())
      .then((data) => {
        setUnprocessedOrders(data.orders);
        setUnprocessedReviews(data.reviews);
      })
      .catch((err) => console.log(err));
  };

  // Fetch unprocessed data on pathname change and every 60 seconds
  useEffect(() => {
    if (pathname !== "/login") {
      fetchUnprocessedData();

      const intervalId = setInterval(() => {
        fetchUnprocessedData();
      }, 60000);

      return () => clearInterval(intervalId);
    }
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return route.private ? (
          <Route exact path={route.route} element={<PrivateRoute />} key={route.key}>
            <Route exact path={route.route} element={route.component} key={route.key} />
          </Route>
        ) : (
          <Route exact path={route.route} element={route.component} key={route.key} />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {pathname !== "/login" && (
        <Sidenav
          color={sidenavColor}
          brand={brand}
          brandName=""
          unprocessedOrders={unprocessedOrders}
          unprocessedReviews={unprocessedReviews}
          miniBrand={miniBrand}
          routes={routes}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        />
      )}
      {pathname !== "/login" && (
        <DashboardLayout>
          <DashboardNavbar />
        </DashboardLayout>
      )}

      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>

      {pathname !== "/login" && (
        <DashboardLayout>
          <Footer company={{ name: "Mama Za Pet" }} />
        </DashboardLayout>
      )}
    </ThemeProvider>
  );
};

export default App;
