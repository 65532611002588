import { useEffect, useState } from "react";

import { Card, CircularProgress, Grid, Icon, Typography } from "@mui/material";

import SoftBox from "components/SoftBox";

import DashboardLayout from "pages/layout/dashboard-layout";
import ReviewCard from "pages/user/review-card";
import SoftPagination from "components/SoftPagination";
import Pagination from "components/Pagination";
import SoftTypography from "components/SoftTypography";
import { get, put, SERVER_URL_PREFIX } from "api";
import SoftButton from "components/SoftButton";
import {
  circularProgressClasses,
} from '@mui/material/CircularProgress';
import { useDocumentTitle } from "hooks/use-document-title";

const Reviews = (props) => {
  useDocumentTitle("Pregled recenzija | Mama za pet - Platforma");
  const [page, setPage] = useState(0);
  const [reload, setReload] = useState(false);

  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviewsError, setReviewsError] = useState(null);

  const [reviewStatusChangeLoading, setReviewStatusChangeLoading] = useState(false);
  const [reviewStatusApproveLoading, setReviewStatusApproveLoading] = useState(false);
  const [reviewStatusRejectLoading, setReviewStatusRejectLoading] = useState(false);
  const [statusChangeReviewId, setStatusChangeReviewId] = useState(null);

  // Step 1: Add state for selected image
  const [selectedImage, setSelectedImage] = useState(null);

  // Step 2: Image click handler
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  // Step 3: Display preview
  const renderImagePreview = () => {
    if (!selectedImage) return null;

    return (
      <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999' }} onClick={() => setSelectedImage(null)}>
        <img src={selectedImage} alt="Preview" style={{ maxWidth: '80%', maxHeight: '80%' }} />
      </div>
    );
  };

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await get(`${SERVER_URL_PREFIX}/api/web/review/pending?size=10&page=${page}`);
        const data = await response.json();
        setReviews(data ? data : []);
      } catch (error) {
        setReviewsError(error);
      } finally {
        setReviewsLoading(false);
      }
    }
    fetchReviews();
  }, [page, reload]);

  const renderPagination = () => {
    return (
      <Pagination
        totalPages={reviews ? reviews.totalPages : 0}
        currentPage={page}
        onPageChange={(newPage) => {
          scrollTo({ top: 0, behavior: 'smooth' });
          setPage(newPage);
        }}
      />
    );
  };

  const handleReviewStatusChange = async (reviewId, status) => {
    try {
      if (status === 'APPROVED') {
        setReviewStatusApproveLoading(true);
      } else if (status === 'REJECTED') {
        setReviewStatusRejectLoading(true);
      }
      setReviewStatusChangeLoading(true);
      setStatusChangeReviewId(reviewId);

      await put(`${SERVER_URL_PREFIX}/api/admin/review/${reviewId}/status/${status}`);

      if (status === 'APPROVED') {
        setReviewStatusApproveLoading(false);
      } else if (status === 'REJECTED') {
        setReviewStatusRejectLoading(false);
      }
      setReviewStatusChangeLoading(false);
      setStatusChangeReviewId(null);
      setReload(!reload);
    } catch (error) {
      console.error(error);
    } finally {
      setReviewStatusApproveLoading(false);
      setReviewStatusRejectLoading(false);
      setReviewStatusChangeLoading(false);
    };
  }

  if (reviewsLoading) {
    return (
      <DashboardLayout>
        <Card sx={{ p: 3, mt: 3 }}>
          <Typography variant="h4" mb="2rem">Recenzije</Typography>
          <SoftBox display="flex" justifyContent="flex-start" gap="1rem">
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                color: '#7eddd3',
                animationDuration: '550ms',
                // position: 'absolute',
                // left: 0,
                [`& .${circularProgressClasses.circle}`]: {
                  strokeLinecap: 'round',
                },
              }}
              size={20}
              thickness={4}
            />
            <SoftTypography variant="h5">Učitavanje recenzija...</SoftTypography>
          </SoftBox>
        </Card>
      </DashboardLayout>
    )
  }

  if (reviewsError) {
    return (
      <DashboardLayout>
        <Card sx={{ p: 3, mt: 3 }}>
          <Typography variant="h4" mb="2rem">Recenzije</Typography>
          <SoftBox display="flex" justifyContent="flex-start" gap="1rem">
            <SoftTypography variant="h6" color="error">Došlo je do greške prilikom učitavanja recenzija.</SoftTypography>
          </SoftBox>
        </Card>
      </DashboardLayout>
    )
  }

  if (reviews.content.length === 0) {
    return (
      <DashboardLayout>
        <Card sx={{ p: 3, mt: 3 }}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb="1rem">
            <Typography variant="h4">Recenzije</Typography>
            <SoftButton variant={"outlined"} color="dark" onClick={() => setReload(prev => !prev)}>
              <Icon>refresh</Icon>&nbsp;
            </SoftButton>
          </SoftBox>
          <SoftBox display="flex" justifyContent="flex-start" alignItems="center" gap="0.5rem">
            <Icon fontSize="medium">content_paste_off</Icon>
            <SoftTypography variant="body2">Trenutno nema neodobrenih recenzija</SoftTypography>
          </SoftBox>
        </Card>
      </DashboardLayout>
    )
  }

  return (
    <DashboardLayout>
      <Grid container>
        <Grid item xs={12}>
          <SoftBox my={3}>
            <Card sx={{ p: 3, mt: 3 }}>
              <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb="1rem">
                <Typography variant="h4">Recenzije</Typography>
                <SoftButton variant={"outlined"} color="dark" onClick={() => setReload(prev => !prev)}>
                  <Icon>refresh</Icon>&nbsp;
                </SoftButton>
              </SoftBox>
              <SoftBox
                display="flex"
                justifyContent="center"
                flexDirection="column"
                maxWidth="1200px"
                width="100%"
                mt="2rem"
              >
                {
                  reviews.content.map(review => (
                    <SoftBox
                      key={review.customerName + review.product.id + review.createdAt}
                      display="flex"
                      flexDirection="column"
                      width="100%"
                      sx={{
                        borderTop: '1px solid #eee',
                        py: '1.5rem',
                      }}
                    >
                      <ReviewCard
                        name={review.customerName}
                        rating={Number(review.rating)}
                        text={review.review}
                        date={review.createdAt}
                        images={review.images}
                        product={review.product}
                        onImageClick={handleImageClick}
                      />
                      <SoftBox display="flex" mt="20px" gap="10px">
                        <SoftButton variant="contained" color="primary" disabled={reviewStatusApproveLoading} onClick={() => handleReviewStatusChange(review.id, 'APPROVED')}>
                          {reviewStatusApproveLoading && statusChangeReviewId === review.id && <CircularProgress color="white" size="1rem" sx={{ mr: "10px" }} />}
                          Odobri
                        </SoftButton>
                        <SoftButton variant="contained" color="error" disabled={reviewStatusChangeLoading} onClick={() => handleReviewStatusChange(review.id, 'REJECTED')}>
                          {reviewStatusRejectLoading && statusChangeReviewId === review.id && <CircularProgress color="white" size="1rem" sx={{ mr: "10px" }} />}
                          Odbaci
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  ))
                }
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                sx={{
                  borderTop: '1px solid #eee',
                  pt: '1.25rem',
                }}
                maxWidth="1200px"
                width="100%"
              >
                <SoftBox mb={{ xs: 3, sm: 0 }}>
                  <SoftTypography variant="button" color="secondary" fontWeight="regular">
                    Ukupan broj recenzija: {reviews.totalElements}
                  </SoftTypography>
                </SoftBox>
                <SoftPagination>
                  {renderPagination()}
                </SoftPagination>
              </SoftBox>
            </Card>
          </SoftBox>
        </Grid>
      </Grid>
      {selectedImage && renderImagePreview()}
    </DashboardLayout>
  )
}

export default Reviews;