import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import moment from "moment";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import SoftBox from "components/SoftBox";

import DashboardLayout from "pages/layout/dashboard-layout";
import Header from "pages/admin/order-details/components/header";
import OrderInfo from "pages/admin/order-details/components/order-info";
import OrderSummary from "pages/admin/order-details/components/order-summary";
import BillingInformation from "pages/admin/order-details/components/billing-information";

import { get, patch, SERVER_URL_PREFIX } from "api";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { paymentStatusSelectData, shipmentStatusSelectData } from "./data/selectData";
import { useDocumentTitle } from "hooks/use-document-title";

function OrderDetails() {
  useDocumentTitle("Detalji narudžbe | Mama za pet - Platforma");

  const { orderId } = useParams();
  const [order, setOrder] = useState(undefined);
  const [isLoadingOrder, setIsLoadingOrder] = useState(false);
  const [isErrorOrder, setIsErrorOrder] = useState(false);

  const [isLoadingUpdateOrder, setIsLoadingUpdateOrder] = useState(false);
  const [isErrorUpdateOrder, setIsErrorUpdateOrder] = useState(false);

  const getOrder = async (orderId) => {
    setIsLoadingOrder(true);
    get(`${SERVER_URL_PREFIX}/api/admin/order/${orderId}`)
      .then((response) => response.json())
      .then((data) => setOrder(data))
      .catch((e) => {
        setIsErrorOrder(true);
      })
      .finally(() => {
        setIsLoadingOrder(false);
      });
  };

  useEffect(() => {
    getOrder(orderId);
  }, [orderId]);

  const updateOrderStatuses = async ({ shippingStatus, paymentStatus }) => {
    setIsLoadingUpdateOrder(true);
    try {
      await patch(`${SERVER_URL_PREFIX}/api/admin/order/${order.id}/statuses`, {
        shippingStatus: shippingStatus,
        paymentStatus: paymentStatus,
      });
    } catch (e) {
      setIsErrorUpdateOrder(true);
    } finally {
      setIsLoadingUpdateOrder(false);
    }
  };

  const updateOrder = async ({ shippingStatus, paymentStatus }) => {
    const newSwal = Swal.mixin({
      customClass: {
        confirmButton: "button button-info",
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    const result = await newSwal.fire({
      title: "Sačuvaj izmjene?",
      text: "Želite li sačuvati izmjene na narudžbi?",
      showCancelButton: true,
      confirmButtonText: "Sačuvaj",
      cancelButtonText: "Odustani",
      reverseButtons: true,
    });

    if (result.value) {
      Swal.fire({
        title: "Spremanje...",
        text: "Molimo vas sačekajte dok se narudžba ažurira.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      const updatePromises = [];

      updatePromises.push(updateOrderStatuses({ shippingStatus, paymentStatus }));

      try {
        await Promise.all(updatePromises);
        Swal.fire("Narudžba ažurirana!", "Narudžba je uspješno ažurirana.", "success");

        // Refresh the order details
        await getOrder(orderId);
      } catch (error) {
        Swal.fire("Greška!", "Dogodila se greška prilikom ažuriranja narudžbe.", "error");
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      // Optionally handle the cancel case
    }
  };

  if (isLoadingOrder) {
    return (
      <DashboardLayout>
        <SoftBox my={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <SoftBox py={2} textAlign="center">
                  <SoftTypography sx={{ my: "1rem" }} variant="body2">
                    Podaci se učitavaju...
                  </SoftTypography>
                  <CircularProgress color="success" sx={{ my: "1rem" }} />
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  if (isErrorOrder) {
    return (
      <DashboardLayout>
        <SoftBox my={4}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <SoftBox p={1} mb={1} textAlign="center">
                  <SoftTypography sx={{ my: "1rem" }} variant="body2" color="error">
                    Došlo je do greške prilikom učitavanja podataka
                  </SoftTypography>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <SoftBox my={4}>
        {order && (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <Header
                    orderId={order.id}
                    publicOrderId={order.publicId}
                    date={moment(new Date(order.timestamp)).format("LLL")}
                    shipmentStatus={shipmentStatusSelectData.find(
                      (s) => s.value === order.shippingStatus
                    )}
                    paymentStatus={paymentStatusSelectData.find(
                      (s) => s.value === order.paymentStatus
                    )}
                    onOrderUpdate={updateOrder}
                  />
                </SoftBox>
                <Divider />
                <SoftBox pt={1} pb={3} px={2}>
                  <SoftBox mb={3}>
                    <OrderInfo products={order.products} currency={order.currency} />
                  </SoftBox>
                  <Divider />
                  <SoftBox mt={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={9}>
                        <BillingInformation
                          fullName={order.customer.fullName}
                          address={order.customer.streetAndNumber}
                          postalCode={order.customer.postalCode}
                          city={order.customer.city}
                          country={order.customer.country.label}
                          email={order.customer.email}
                          phoneNumber={order.customer.phoneNumber}
                          notes={order.notes}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} sx={{ ml: "auto" }}>
                        <OrderSummary
                          productsPrice={order.price}
                          currency={order.currency}
                          basePrice={order.basePrice}
                          specialOffersPrice={order.specialOffersPrice}
                          shippingPrice={order.shipping}
                          subtotal={order.subtotal}
                          total={order.total}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        )}
      </SoftBox>
    </DashboardLayout>
  );
}

export default OrderDetails;
