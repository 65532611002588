import PropTypes from "prop-types";

import { useSoftUIController } from "context";

import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SoftBox from "components/SoftBox";

import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "app/components/sidenav/styles/sidenavCollapse";
import SoftBadge from "components/SoftBadge";
import SoftTypography from "components/SoftTypography";

function SidenavCollapse({
  color = "info",
  icon,
  name,
  children,
  active,
  noCollapse,
  open,
  badgeValue,
  ...rest
}) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;

  return (
    <>
      <ListItem component="li">
        <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
          {miniSidenav && !!badgeValue ? (
            <SoftBadge
              variant="contained"
              color="dark"
              badgeContent={
                <SoftTypography variant="caption" color="white" sx={{ fontWeight: "bold" }}>
                  {badgeValue}
                </SoftTypography>
              }
              size="xs"
            >
              <ListItemIcon
                sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
              >
                {typeof icon === "string" ? (
                  <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
                ) : (
                  icon
                )}
              </ListItemIcon>
            </SoftBadge>
          ) : (
            <ListItemIcon
              sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, sidenavColor })}
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
          )}
          <ListItemText
            primary={name}
            sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
          />
          <Icon
            sx={(theme) =>
              collapseArrow(theme, { noCollapse, transparentSidenav, miniSidenav, open })
            }
          >
            expand_less
          </Icon>

          {!miniSidenav && !!badgeValue && (
            <SoftBadge
              variant="contained"
              color="dark"
              badgeContent={
                <SoftTypography variant="caption" color="white" sx={{ fontWeight: "bold" }}>
                  {badgeValue}
                </SoftTypography>
              }
              container
              size="xs"
            />
          )}
        </SoftBox>
      </ListItem>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
  badgeValue: PropTypes.string,
};

export default SidenavCollapse;
