import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

import SoftBox from "components/SoftBox";

import Footer from "pages/user/components/footer";
import Configurator from "pages/user/configurator";
import DefaultNavbar2 from "app/components/Navbars/DefaultNavbar";

import theme from "assets/theme";
import userRoutes from "routes/user.routes";
// import initializeAnalytics from 'analytics';

const UserApp = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    let top = 0;
    if (hash) {
      const element = document.getElementById(hash.substring(1));
      top = element.offsetTop - 100;
    }
    document.documentElement.scrollTop = top;
    document.scrollingElement.scrollTop = top;
  }, [pathname]);

  // useEffect(() => {
  //   initializeAnalytics();
  // }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Configurator />
      <SoftBox sx={{ backgroundColor: "#ffffff", minHeight: "100vh", display: "flex", flexDirection: "column" }}>
        {/* <DefaultNavbar
          routes={userRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
          light
        /> */}
        <DefaultNavbar2
          routes={userRoutes}
          action={{
            type: "external",
            route: "https://creative-tim.com/product/soft-ui-dashboard-pro-react",
            label: "buy now",
          }}
          transparent
        // light
        />
        <Routes>
          {getRoutes(userRoutes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <SoftBox sx={{ mt: "6rem" }} />
        <Footer />
      </SoftBox>
    </ThemeProvider>
  );
}

export default UserApp;