import { useEffect } from 'react';

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
};

const useMetaTag = (name, content) => {
  useEffect(() => {
    const metaTag = document.querySelector(`meta[name="${name}"]`);
    if (metaTag) {
      metaTag.content = content;
    } else {
      const meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      document.head.appendChild(meta);
    }
  }, [name, content]);
};

const useMetaPropertyTag = (property, content) => {
  useEffect(() => {
    const metaTag = document.querySelector(`meta[property="${property}"]`);
    if (metaTag) {
      metaTag.content = content;
    } else {
      const meta = document.createElement('meta');
      meta.setAttribute('property', property);
      meta.content = content;
      document.head.appendChild(meta);
    }
  }, [property, content]);
};

export { useDocumentTitle, useMetaTag, useMetaPropertyTag };