import { Divider, Grid, Icon, Link } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import ProductItem from "pages/user/configurator/product-item";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SoftButton from "components/SoftButton";
import { get, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { useDocumentTitle } from "hooks/use-document-title";
import { ReactComponent as Priority } from "assets/icons/priority.svg";
import { useMetaTag } from "hooks/use-document-title";
import { formatPrice } from "utils/formatPrice";
import { useSoftUIController } from "context";

const ThankYou = () => {
  useDocumentTitle("Hvala na narudžbi | Mama za pet – Sigurna Kupovina");
  useMetaTag(
    "description",
    "Hvala na narudžbi. Pregledajte detalje narudžbe i očekujte dostavu u roku od 2-3 radna dana."
  );
  useMetaTag(
    "keywords",
    "mama za pet hvala na narudžbi, mama za pet narudžba, mama za pet sigurna kupovina, mama za pet dostava, mama za pet dostava na kućnu adresu"
  );

  const [controller] = useSoftUIController();
  const { selectedCurrency } = controller;

  const { orderId } = useParams();
  const [order, setOrder] = useState();

  useEffect(() => {
    get(`${SERVER_URL_PREFIX}/api/web/order/public/${orderId}`)
      .then((response) => response.json())
      .then((data) => setOrder(data));
  }, []);

  if (order === undefined) return null;

  if (order?.timestamp && new Date() - new Date(order.timestamp) > 24 * 60 * 60 * 1000) {
    return (
      <SoftBox display="flex" justifyContent="center" alignItems="center">
        <SoftTypography variant="h4" sx={{ mt: "2rem" }} color="error">
          Link za pregled detalja narudžbe je istekao
        </SoftTypography>
      </SoftBox>
    );
  }

  return (
    <PageLayout>
      <Grid container>
        <Grid item xs={12}>
          <Divider sx={{ mb: "0" }} />
          <SoftBox
            display="flex"
            sx={({ palette }) => ({
              flexDirection: "column",
              backgroundColor: palette.success.main,
              p: { xs: "1rem" },
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Priority fill="#ffffff" width={60} height={60} />
            <SoftTypography
              sx={({ typography }) => ({
                fontSize: { xs: typography.size.lg, lg: typography.size.xl },
                textAlign: "center",
                color: "#fff",
              })}
            >
              Uspješno ste kreirali narudžbu sa brojem &nbsp;{" "}
              <SoftTypography
                sx={({ typography }) => ({
                  color: "#fff",
                  fontSize: { xs: typography.size.lg, lg: typography.size.xl },
                  fontWeight: typography.fontWeightBold,
                })}
                component="span"
                display="block"
              >
                {orderId}
              </SoftTypography>
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center", mb: "3rem" }}>
          <SoftBox
            display="flex"
            flexDirection="column"
            sx={({ palette }) => ({
              backgroundColor: palette.grey[100],
              p: "2rem",
              justifyContent: "center",
            })}
          >
            <SoftBox display="flex" justifyContent="center" sx={{ textAlign: "center" }}>
              <SoftTypography variant="body2" sx={{ fontStyle: "italic" }}>
                Svi podaci o narudžbi će biti poslati na Vaš email&nbsp;
                <SoftTypography
                  variant="body2"
                  component="span"
                  sx={({ typography }) => ({
                    fontWeight: typography.fontWeightBold,
                  })}
                >
                  {order.customer.email}
                </SoftTypography>
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            flexDirection="column"
            sx={{ maxWidth: "35rem", margin: "0 auto", px: "1rem" }}
          >
            {order.products.map((item) => (
              <ProductItem
                key={item.id}
                id={item.id}
                name={item.name}
                price={
                  order.currency === "BAM" ? String(item.unitPriceBam) : String(item.unitPriceEur)
                }
                unitQuantity={String(item.quantity)}
                currency={order.currency}
                realPrice={
                  order.currency === "BAM"
                    ? item.unitPriceBam * item.ordersQuantity
                    : item.unitPriceEur * item.ordersQuantity
                }
                calculatedPrice={item.calculatedPrice}
                quantity={item.ordersQuantity}
                img={`${RESOURCES_SERVER_URL_PREFIX}${item.imageLocation}`}
                variant="preview"
              />
            ))}
            <Divider sx={{ mt: "1.5rem" }} />
            {order && order.couponValue !== 0 && (
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb="0.5rem"
              >
                <SoftBox>
                  <SoftTypography
                    sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                      fontWeight: typography.fontWeightRegular,
                    })}
                  >
                    Popust od kupona{" "}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex">
                  <SoftTypography
                    sx={({ typography }) => ({
                      fontSize: typography.size.sm,
                      fontWeight: typography.fontWeightBold,
                      // fontWeight: typography.fontWeightBold,
                      color: order.couponValue ? "success.focus" : "#98999e",
                    })}
                  >
                    {order.couponValue > 0 ? "-" : ""}
                    {Number(order.couponValue).toFixed(2)}
                    {selectedCurrency.label === "BAM" ? "KM" : selectedCurrency.label}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            <Divider sx={{ mt: "0.5rem", mb: "1rem" }} />
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftTypography variant="button" fontWeight="regular">
                Iznos narudžbe:
              </SoftTypography>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                })}
              >
                {formatPrice(Number(order.subtotal), order.currency)}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftTypography variant="button" fontWeight="regular">
                Dostava:
              </SoftTypography>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.sm,
                })}
              >
                {formatPrice(Number(order.shipping), order.currency)}
              </SoftTypography>
            </SoftBox>
            <Divider sx={{ mt: "1rem" }} />
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" mt="0.5rem">
              <SoftBox>
                <SoftTypography variant="body2">
                  Ukupno &nbsp;
                  <SoftTypography variant="caption">(uključujući dostavu i popuste)</SoftTypography>
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: typography.size.md,
                })}
              >
                &nbsp;&nbsp;{formatPrice(Number(order.price), order.currency)}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </Grid>
        <Grid item xs={12} mt="5rem" mb="1rem">
          <SoftBox display="flex" justifyContent="center" sx={{ margin: "0 auto" }}>
            <SoftButton
              component={Link}
              href="/store"
              color="info"
              variant="gradient"
              sx={{ maxWidth: "25rem", mx: "1rem" }}
              fullWidth
            >
              <Icon>shopping_bag</Icon>&nbsp; Nastavi kupovinu
            </SoftButton>
          </SoftBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ThankYou;
