import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import PageLayout from "pages/layout/page-layout";
import ProductReviewItem from "pages/user/review-survey/product-review-item";
import { Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import SoftButton from "components/SoftButton";
import { useSearchParams } from "react-router-dom";
import { get, post, SERVER_URL_PREFIX, RESOURCES_SERVER_URL_PREFIX } from "api";
import { CircularProgress, Divider, Grid, Icon, Link } from "@mui/material";
import { useDocumentTitle } from "hooks/use-document-title";
import { ReactComponent as Reviews } from "assets/icons/reviews.svg";
import { useMetaTag } from "hooks/use-document-title";
import ScrollableProductList from "pages/user/product-pages/components/scrollable-product-list";
import { useSoftUIController } from "context";
import { setOpenConfigurator } from "context";
import { addCartItem } from "context";
import useGTMEvent from "hooks/use-gtm-event";
import { updateProduct } from "context";
import brand from "assets/images/logos/mini-logo-transparent-green.png";
import config from "config/config";

const ReviewSurvey = () => {
  useDocumentTitle("Recenzije - Forma | Mama za pet kozmetički proizvodi");
  useMetaTag(
    "description",
    "Ostavite recenziju proizvoda koje ste kupili na Mama za pet. Pomoću recenzija pomažete drugim korisnicima da donesu odluku o kupovini."
  );
  useMetaTag(
    "keywords",
    "mama za pet recenzije, mama za pet ocjene, mama za pet iskustva, mama za pet korisničke recenzije, mama za pet kupovina"
  );

  const [controller, dispatch] = useSoftUIController();
  const { selectedCurrency, cartItems } = controller;
  const sendEventToGTM = useGTMEvent();

  const [reviewRequestData, setReviewRequestData] = useState({});
  const [errorOccured, setErrorOccured] = useState(false);
  const [searchParams] = useSearchParams();
  const [submitCompleted, setSubmitCompleted] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [images, setImages] = useState({});
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [products, setProducts] = useState([]);

  const reviewRequestPublicId = searchParams.get("reviewRequestPublicId");
  const expiration = searchParams.get("expiration");
  const isLinkExpired = expiration ? new Date(Number(expiration)) < new Date() : false;

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, true);

  useEffect(() => {
    setIsLoadingProducts(true);
    get(`${SERVER_URL_PREFIX}/api/web/products`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data);
      })
      .finally(() => {
        setIsLoadingProducts(false);
      });
  }, []);

  const handleAddToCart = (product, eventSource) => {
    const currentProduct = cartItems.find((item) => item.id === product.id);
    get(
      `${SERVER_URL_PREFIX}/api/web/product/price/calculation?productId=${product.id}&quantity=${currentProduct ? currentProduct.quantity + 1 : product.quantity
      }&currency=${selectedCurrency.value.toUpperCase()}`
    )
      .then((response) => response.json())
      .then((data) => {
        if (!currentProduct) {
          addCartItem(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
          });
          sendEventToGTM("add_to_cart", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            quantity: 1,
            page_source: "Review Thank You Page",
            event_source: eventSource,
          });
        } else {
          const newQuantity = currentProduct.quantity + product.quantity;
          updateProduct(dispatch, {
            ...product,
            realPrice: data.realPrice,
            calculatedPrice: data.calculatedPrice,
            quantity: newQuantity,
          });
          sendEventToGTM("update_cart_quantity", {
            product_id: product.id,
            product_name: product.name,
            product_category: product.type,
            product_price: data.realPrice,
            product_calculated_price: data.calculatedPrice,
            currency: selectedCurrency.value,
            new_quantity: newQuantity,
            page_source: "Review Thank You Page",
            event_source: eventSource,
          });
        }
      });
  };

  const handleAdd = (product, eventSource) => {
    setTimeout(handleConfiguratorOpen, 300);
    handleAddToCart(getProductsAddToCardData(product.name, product.quantity), eventSource);
  };

  const getProductsAddToCardData = (productName, productQuantity) => {
    const product = products.find((p) => p.name === productName);

    if (product) {
      const { id, name, imageLocation, quantity, unitPriceBam, unitPriceEur } = product;
      const price = selectedCurrency?.value === "bam" ? unitPriceBam : unitPriceEur;

      return {
        id: String(id),
        name: name,
        img: `${RESOURCES_SERVER_URL_PREFIX}${imageLocation}`,
        quantity: productQuantity,
        unitPrice: price,
        unitQuantity: quantity,
      };
    }

    return undefined;
  };

  useEffect(() => {
    if (isLinkExpired || !reviewRequestPublicId) return;

    get(`${SERVER_URL_PREFIX}/api/web/review/${reviewRequestPublicId}`)
      .then((response) => response.json())
      .then((response) => {
        setReviewRequestData(response);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [isLinkExpired, reviewRequestPublicId]);

  const productReviewsInitialValues = useMemo(
    () =>
      reviewRequestData && reviewRequestData.products
        ? reviewRequestData.products.reduce((acc, product) => {
          acc[`rating_${product.id}`] = 0;
          acc[`review_${product.id}`] = "";
          return acc;
        }, {})
        : {},
    [reviewRequestData]
  );

  function validateReviewsAndRatings(data) {
    if (Object.keys(data).length === 0) {
      return false;
    }

    let hasValidPair = false;

    const indices = new Set(
      Object.keys(data)
        .filter((key) => key.startsWith("rating_") || key.startsWith("review_"))
        .map((key) => key.split("_")[1])
    );

    for (const index of indices) {
      const ratingKey = `rating_${index}`;
      const reviewKey = `review_${index}`;

      const ratingValue = data[ratingKey];
      const reviewText = data[reviewKey];

      const hasRating = ratingValue !== undefined && ratingValue !== null;
      const hasReview = reviewText !== undefined && reviewText !== null;

      if (hasRating || hasReview) {
        if (!(ratingValue >= 1 && ratingValue <= 5 && reviewText && reviewText.length > 0)) {
          return false;
        }

        hasValidPair = true;
      }
    }

    return hasValidPair;
  }

  const generateProductReviews = (values) => {
    const productReviewsMap = new Map();

    Object.keys(values).map((key) => {
      const [prefix, productId] = key.split("_");
      productReviewsMap.set(productId, {
        ...(productReviewsMap.get(productId) || {}),
        [prefix]: values[key],
      });
    });

    const productReviews = [];
    productReviewsMap.forEach((productReview, productId) => {
      productReview.productId = productId;
      productReviews.push(productReview);
    });
    return productReviews;
  };

  const onSubmit = (values, { setSubmitting }) => {
    setErrorOccured(false);

    const hasValidReview = validateReviewsAndRatings(values);
    if (!hasValidReview) {
      setErrorOccured(true);
      setSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("publicOrderId", reviewRequestData.publicOrderId);

    const productReviews = generateProductReviews(values);

    productReviews.forEach((review, index) => {
      formData.append(`productReview_productId_${review.productId}`, review.productId);
      formData.append(`productReview_rating_${review.productId}`, review.rating || 0);
      formData.append(`productReview_review_${review.productId}`, review.review || "");

      if (images[review.productId]) {
        images[review.productId].forEach((file, fileIndex) => {
          formData.append(
            `files`,
            file,
            `image_${reviewRequestData.publicOrderId}_${review.productId}_${fileIndex}.${file.name
              .split(".")
              .pop()}`
          );
        });
      }
    });

    post(`${SERVER_URL_PREFIX}/api/web/review/submit`, formData, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
      },
      body: formData,
    })
      .then((response) =>
        response.status === 200 ? setSubmitCompleted(true) : setSubmitError(true)
      )
      .catch((error) => {
        console.error(error);
        setSubmitError(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (reviewRequestData?.eligible === false) {
    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        gap="2rem"
        padding="1rem 1rem"
        justifyContent="center"
        alignItems="center"
      >
        <SoftBox
          display="flex"
          justifyContent="center"
          sx={{ margin: "0 auto", }}
        >
          <SoftBox
            component="img"
            src={brand}
            alt="Mama za pet brand logo"
            sx={{ height: "4rem", mb: "0 auto" }}
          />
        </SoftBox>
        <SoftTypography variant="h4" sx={{ mt: "0" }}>
          Link za recenziju je iskorišten
        </SoftTypography>
        <SoftBox>
          <SoftButton component={Link} href="/" color="info" variant="gradient" fullWidth>
            <Icon>shopping_bag</Icon>&nbsp; Idi na početnu
          </SoftButton>
        </SoftBox>
      </SoftBox>
    );
  }

  if (!reviewRequestPublicId || !expiration || isLinkExpired) {
    return (
      <SoftBox
        display="flex"
        flexDirection="column"
        gap="2rem"
        padding="5rem 1rem"
        justifyContent="center"
        alignItems="center"
      >
        <SoftTypography variant="h4" sx={{ mt: "2rem" }} color="error">
          Link za recenziju je istekao
        </SoftTypography>
        <SoftBox>
          <SoftButton component={Link} href="/" color="info" variant="gradient" fullWidth>
            <Icon>shopping_bag</Icon>&nbsp; Idi na početnu
          </SoftButton>
        </SoftBox>
      </SoftBox>
    );
  }

  if (submitCompleted) {
    return (
      <PageLayout>
        <Grid container>
          <Grid item xs={12}>
            <Divider sx={{ mb: "0" }} />
            <SoftBox
              display="flex"
              sx={({ palette }) => ({
                flexDirection: "column",
                backgroundColor: palette.success.main,
                p: { xs: "1rem" },
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <Reviews fill="#ffffff" width={60} height={60} />
              <SoftTypography
                sx={({ typography }) => ({
                  fontSize: { xs: typography.size.xl, lg: typography.size.xxl },
                  textAlign: "center",
                  color: "#fff",
                })}
              >
                Hvala što ste ostavili recenziju
              </SoftTypography>
            </SoftBox>
          </Grid>
          {reviewRequestData.rewardCouponEligible && (
            <Grid item xs={12} sx={{ textAlign: "center", mb: "3rem" }}>
              <SoftBox
                display="flex"
                flexDirection="column"
                sx={({ palette }) => ({
                  backgroundColor: palette.grey[100],
                  p: "2rem",
                  justifyContent: "center",
                })}
              >
                <SoftBox display="flex" justifyContent="center" sx={{ textAlign: "center" }}>
                  <SoftTypography variant="body2" sx={{ fontStyle: "italic" }}>
                    Kupon će biti proslijeđen na Vašu email adresu kada recenzija bude odobrena
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            </Grid>
          )}
          <Grid item xs={12}>
            {/* <SoftTypography variant="h6" sx={{ textAlign: "center", mt: "3rem", mb: "3rem" }}>
              Vaša recenzija nam mnogo znači i pomaže drugim kupcima da donesu prave odluke. Ponosni
              smo što kreiramo proizvode koji doprinose vašem zadovoljstvu i ljepoti.
            </SoftTypography> */}
            {/* <Divider sx={{ mb: "0" }} /> */}
            <SoftBox
              display="flex"
              justifyContent="center"
              sx={{ margin: "0 auto", mb: "2rem" }}
            >
              <SoftBox
                component="img"
                src={brand}
                alt="Mama za pet brand logo"
                sx={{ height: "4rem", mb: "0 auto" }}
              />
            </SoftBox>
            {/* <SoftTypography variant="h4" sx={{ textAlign: "center", mt: "2rem", mb: "2rem" }}>
              Istražite našu ponudu i pronađite inspiraciju među najpopularnijim proizvodima
            </SoftTypography> */}
          </Grid>
          <Grid item xs={12} mt="1rem" mb="3rem">
            <SoftBox display="flex" justifyContent="center" sx={{ margin: "0 auto" }}>
              <SoftButton
                component={Link}
                href="/store"
                color="info"
                variant="gradient"
                sx={{ maxWidth: "25rem", mx: "1rem" }}
                fullWidth
              >
                <Icon>shopping_bag</Icon>&nbsp; Posjeti prodavnicu
              </SoftButton>
            </SoftBox>
          </Grid>
          <Grid item xs={12}>
            <ScrollableProductList
              products={products}
              selectedCurrency={selectedCurrency}
              handleAddToCart={(product) => handleAdd(product, "suggestions_add_to_cart_button")}
            />
          </Grid>
          <Divider sx={{ mb: "0" }} />
        </Grid>
      </PageLayout>
    );
  }

  if (submitError) {
    return (
      <>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="h4" sx={{ mt: "2rem" }} color="error">
            Desila se greška
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="body2" sx={{ mt: "2rem" }}>
            Kontaktirajte podrsku mama za pet na email <b>{config.contact.infoEmail}</b>
          </SoftTypography>
        </SoftBox>
      </>
    );
  }

  return (
    <PageLayout
      sx={{ overflow: "hidden", display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Divider sx={{ width: "100%" }} />
      <SoftBox display="flex" justifyContent="center" id="review-heading-container">
        <SoftTypography variant="h3">Forma za recenzije</SoftTypography>
      </SoftBox>
      <Divider sx={{ width: "100%", mb: 0 }} />

      {reviewRequestData.rewardCouponEligible && (
        <SoftBox
          display="flex"
          sx={({ palette }) => ({
            backgroundColor: palette.grey[100],
            p: "1rem",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
          })}
        >
          <SoftTypography
            sx={({ typography }) => ({
              fontSize: typography.size.sm,
              textAlign: { xs: "center", md: "center" },
            })}
          >
            Popunjavanjem recenzije dobićete <b>kupon za popust</b> koji možete iskoristiti u
            narednih 90 dana.
          </SoftTypography>
          <SoftBox display={{ xs: "flex", md: "none" }} justifyContent="center" mt="1rem"></SoftBox>
          <SoftTypography
            sx={({ typography }) => ({
              fontSize: typography.size.sm,
              textAlign: { xs: "center", md: "center" },
            })}
          >
            Kada recenzija bude odobrena, kupon će biti proslijeđen na Vašu email adresu{" "}
            <b>{reviewRequestData.email}</b>.&nbsp;
          </SoftTypography>
        </SoftBox>
      )}

      <SoftBox display="flex" justifyContent="center" width="100%" px="1rem" mt="3rem">
        <Formik initialValues={productReviewsInitialValues} onSubmit={onSubmit}>
          {({ values, errors, touched, isSubmitting, setValues }) => (
            <Form
              id={"review-form"}
              autoComplete="off"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "900px",
                gap: "3rem",
              }}
            >
              {reviewRequestData.products?.map((product, index) => (
                <ProductReviewItem
                  key={product.id}
                  productId={String(product.id)}
                  name={product.name}
                  price={100}
                  unitQuantity={product.quantity}
                  img={`${RESOURCES_SERVER_URL_PREFIX}${product.imageLocation}`}
                  ratingFieldName={`rating_${product.id}`}
                  reviewFieldName={`review_${product.id}`}
                  values={values}
                  errors={errors}
                  touched={touched}
                  isSubmitting={isSubmitting}
                  setValues={setValues}
                  setImages={setImages}
                />
              ))}
              <SoftTypography
                component="div"
                variant="caption"
                sx={{ fontStyle: "italic", fontSize: "0.875rem" }}
                ml={{ xs: "0", md: "12.7rem" }}
                pl={{ xs: "0", md: "1rem" }}
              >
                Napomena: Sva polja označena zvjezdicom (*) su obavezna.
              </SoftTypography>
              <SoftBox
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems={{ xs: "flex-end", md: "center" }}
                justifyContent={{ xs: "left", md: "right" }}
                gap="2rem"
                ml={{ xs: "0", md: "12.7rem" }}
                pl={{ xs: "0", md: "1rem" }}
              >
                {errorOccured && (
                  <SoftTypography
                    color="error"
                    fontSize="14px"
                    fontWeight="medium"
                    alignSelf="flex-start"
                  >
                    Morate ostaviti recenziju i ocjenu za najmanje jedan proizvod. Takođe, sve
                    započete recenzije moraju biti dovršene.
                  </SoftTypography>
                )}

                <SoftButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="gradient"
                  color="success"
                  sx={{
                    width: "fit-content",
                    minWidth: "10rem",
                  }}
                >
                  {isSubmitting ? (
                    <CircularProgress size={20} color="inherit" sx={{ marginRight: "1rem" }} />
                  ) : null}
                  Pošalji recenzije
                </SoftButton>
              </SoftBox>
            </Form>
          )}
        </Formik>
      </SoftBox>
    </PageLayout>
  );
};

export default ReviewSurvey;
